body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar{
  background: #F55A5A;
  display: flex;
  align-items: center;
}
.nav-logo{
  margin-left: auto;
  
}
.navbar h4{
  margin-right: auto;
  margin-left: 5px;
}

.placeImage{
  width: 125px;
  height: 168px;
  margin-top: 50px;
  position: absolute;
  margin-left: 20px;
}
.location{
  display: flex;
  align-items: center;
  margin-left: 160px;
  /* position: absolute; */
  letter-spacing: 0.17em;
  line-height: 0px;
  
}
.description{
  margin-left: 160px;
  line-height: 20px;
    
}
.description h2{
  line-height: 0;
}
